/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createMachineFromDiscriminatorValue, createProblemDetailsFromDiscriminatorValue, type Machine, type ProblemDetails } from '../../../models/';
// @ts-ignore
import { EarningHistoryRequestBuilderRequestsMetadata, type EarningHistoryRequestBuilder } from './earningHistory/';
// @ts-ignore
import { type BaseRequestBuilder, type KeysToExcludeForNavigationMetadata, type NavigationMetadata, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Operations for a single machine
 */
export interface WithMachine_ItemRequestBuilder extends BaseRequestBuilder<WithMachine_ItemRequestBuilder> {
    /**
     * Operations for a single machine
     */
    get earningHistory(): EarningHistoryRequestBuilder;
    /**
     * Gets a single machine by its unique identifier
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<Machine>}
     * @throws {ProblemDetails} error when the service returns a 401 status code
     * @throws {ProblemDetails} error when the service returns a 404 status code
     * @throws {ProblemDetails} error when the service returns a 4XX or 5XX status code
     */
     get(requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<Machine | undefined>;
    /**
     * Gets a single machine by its unique identifier
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toGetRequestInformation(requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * Uri template for the request builder.
 */
export const WithMachine_ItemRequestBuilderUriTemplate = "{+baseurl}/v2/machines/{machine_id}";
/**
 * Metadata for all the navigation properties in the request builder.
 */
export const WithMachine_ItemRequestBuilderNavigationMetadata: Record<Exclude<keyof WithMachine_ItemRequestBuilder, KeysToExcludeForNavigationMetadata>, NavigationMetadata> = {
    earningHistory: {
        requestsMetadata: EarningHistoryRequestBuilderRequestsMetadata,
    },
};
/**
 * Metadata for all the requests in the request builder.
 */
export const WithMachine_ItemRequestBuilderRequestsMetadata: RequestsMetadata = {
    get: {
        uriTemplate: WithMachine_ItemRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            401: createProblemDetailsFromDiscriminatorValue as ParsableFactory<Parsable>,
            404: createProblemDetailsFromDiscriminatorValue as ParsableFactory<Parsable>,
            XXX: createProblemDetailsFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createMachineFromDiscriminatorValue,
    },
};
/* tslint:enable */
/* eslint-enable */
