/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import {
  type AdditionalDataHolder,
  type ApiError,
  type Parsable,
  type ParseNode,
  type SerializationWriter,
} from '@microsoft/kiota-abstractions'
// @ts-ignore
import { type Guid } from 'guid-typescript'

/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {MachineEarningHistory_earnings}
 */
export function createMachineEarningHistory_earningsFromDiscriminatorValue(): (
  instance?: Parsable,
) => Record<string, (node: ParseNode) => void> {
  return deserializeIntoMachineEarningHistory_earnings
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {MachineEarningHistory}
 */
export function createMachineEarningHistoryFromDiscriminatorValue(): (
  instance?: Parsable,
) => Record<string, (node: ParseNode) => void> {
  return deserializeIntoMachineEarningHistory
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {Machine}
 */
export function createMachineFromDiscriminatorValue(): (
  instance?: Parsable,
) => Record<string, (node: ParseNode) => void> {
  return deserializeIntoMachine
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {MachineList}
 */
export function createMachineListFromDiscriminatorValue(): (
  instance?: Parsable,
) => Record<string, (node: ParseNode) => void> {
  return deserializeIntoMachineList
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {ProblemDetails}
 */
export function createProblemDetailsFromDiscriminatorValue(): (
  instance?: Parsable,
) => Record<string, (node: ParseNode) => void> {
  return deserializeIntoProblemDetails
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoMachine(
  machine: Partial<Machine> | undefined = {},
): Record<string, (node: ParseNode) => void> {
  return {
    create_time: (n) => {
      machine.create_time = n.getDateValue()
    },
    lifetime_balance: (n) => {
      machine.lifetime_balance = n.getNumberValue()
    },
    machine_id: (n) => {
      machine.machine_id = n.getGuidValue()
    },
    update_time: (n) => {
      machine.update_time = n.getDateValue()
    },
  }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoMachineEarningHistory(
  machineEarningHistory: Partial<MachineEarningHistory> | undefined = {},
): Record<string, (node: ParseNode) => void> {
  return {
    earnings: (n) => {
      machineEarningHistory.earnings = n.getObjectValue<MachineEarningHistory_earnings>(
        createMachineEarningHistory_earningsFromDiscriminatorValue,
      )
    },
    machine_id: (n) => {
      machineEarningHistory.machine_id = n.getGuidValue()
    },
  }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoMachineEarningHistory_earnings(): Record<string, (node: ParseNode) => void> {
  return {}
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoMachineList(
  machineList: Partial<MachineList> | undefined = {},
): Record<string, (node: ParseNode) => void> {
  return {
    continuation_token: (n) => {
      machineList.continuation_token = n.getStringValue()
    },
    items: (n) => {
      machineList.items = n.getCollectionOfObjectValues<Machine>(createMachineFromDiscriminatorValue)
    },
  }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoProblemDetails(
  problemDetails: Partial<ProblemDetails> | undefined = {},
): Record<string, (node: ParseNode) => void> {
  return {
    detail: (n) => {
      problemDetails.detail = n.getStringValue()
    },
    instance: (n) => {
      problemDetails.instance = n.getStringValue()
    },
    status: (n) => {
      problemDetails.status = n.getNumberValue()
    },
    title: (n) => {
      problemDetails.title = n.getStringValue()
    },
    type: (n) => {
      problemDetails.type = n.getStringValue() ?? 'about:blank'
    },
  }
}
export type EarningHistoryTimeframeEnum =
  (typeof EarningHistoryTimeframeEnumObject)[keyof typeof EarningHistoryTimeframeEnumObject]
/**
 * Represents a machine
 */
export interface Machine extends Parsable {
  /**
   * The time the machine was created
   */
  create_time?: Date
  /**
   * The lifetime earnings of the machine
   */
  lifetime_balance?: number
  /**
   * The unique machine identifier
   */
  machine_id?: Guid
  /**
   * The time the machine was last updated
   */
  update_time?: Date
}
/**
 * Represents a machine earning history
 */
export interface MachineEarningHistory extends Parsable {
  /**
   * The machine earnings histogram
   */
  earnings?: MachineEarningHistory_earnings
  /**
   * The unique machine identifier
   */
  machine_id?: Guid
}
/**
 * The machine earnings histogram
 */
export interface MachineEarningHistory_earnings extends AdditionalDataHolder, Parsable {
  /**
   * Stores additional data not described in the OpenAPI description found when deserializing. Can be used for serialization as well.
   */
  additionalData?: Record<string, unknown>
}
/**
 * Represents a list of machines
 */
export interface MachineList extends Parsable {
  /**
   * The continuation token to fetch the next page of results
   */
  continuation_token?: string
  /**
   * The list of machines on the current page
   */
  items?: Machine[]
}
/**
 * Represents a problem
 */
export interface ProblemDetails extends ApiError, Parsable {
  /**
   * A human-readable explanation specific to this occurrence of the problem
   */
  detail?: string
  /**
   * The URI reference that identifies the specific occurrence of the problem
   */
  instance?: string
  /**
   * The HTTP status code generated by the origin server
   */
  status?: number
  /**
   * A short, human-readable summary of the problem type
   */
  title?: string
  /**
   * The URI reference that identifies the problem type
   */
  type?: string
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeMachine(writer: SerializationWriter, machine: Partial<Machine> | undefined = {}): void {
  writer.writeDateValue('create_time', machine.create_time)
  writer.writeNumberValue('lifetime_balance', machine.lifetime_balance)
  writer.writeGuidValue('machine_id', machine.machine_id)
  writer.writeDateValue('update_time', machine.update_time)
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeMachineEarningHistory(
  writer: SerializationWriter,
  machineEarningHistory: Partial<MachineEarningHistory> | undefined = {},
): void {
  writer.writeObjectValue<MachineEarningHistory_earnings>(
    'earnings',
    machineEarningHistory.earnings,
    serializeMachineEarningHistory_earnings,
  )
  writer.writeGuidValue('machine_id', machineEarningHistory.machine_id)
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeMachineEarningHistory_earnings(
  writer: SerializationWriter,
  machineEarningHistory_earnings: Partial<MachineEarningHistory_earnings> | undefined = {},
): void {
  writer.writeAdditionalData(machineEarningHistory_earnings.additionalData)
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeMachineList(
  writer: SerializationWriter,
  machineList: Partial<MachineList> | undefined = {},
): void {
  writer.writeStringValue('continuation_token', machineList.continuation_token)
  writer.writeCollectionOfObjectValues<Machine>('items', machineList.items, serializeMachine)
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeProblemDetails(
  writer: SerializationWriter,
  problemDetails: Partial<ProblemDetails> | undefined = {},
): void {
  writer.writeStringValue('detail', problemDetails.detail)
  writer.writeStringValue('instance', problemDetails.instance)
  writer.writeNumberValue('status', problemDetails.status)
  writer.writeStringValue('title', problemDetails.title)
  writer.writeStringValue('type', problemDetails.type ?? 'about:blank')
}
/**
 * The earning history timeframe
 */
export const EarningHistoryTimeframeEnumObject = {
  TwoFourh: '24h',
  Sevend: '7d',
  ThreeZerod: '30d',
} as const
/* tslint:enable */
/* eslint-enable */
