import type { AxiosInstance } from 'axios'
import type { Guid } from 'guid-typescript'
import { DateTime } from 'luxon'
import { action, computed, flow, observable, runInAction } from 'mobx'
import type { Moment } from 'moment'
import moment from 'moment'
import type { MachinesApiClient } from '../../api/machinesApiClient/generated/machinesApiClient'
import type {
  EarningHistoryTimeframeEnum,
  Machine,
  MachineEarningHistory,
} from '../../api/machinesApiClient/generated/models'
import { getMachinesApiClient } from '../../api/machinesApiClient/getMachinesApiClient'
import type { ChartDaysShowing, CurrentHourlyEarningRatesPerMachine, EarningPerMachine, EarningWindow } from './models'
import {
  batchEarningsWindow,
  getBaseKeyAsGuid,
  getEarningWindowsGroupedByDay,
  getMachineEarningRateEndpointPath,
  normalizeEarningHistory,
  normalizeEarningsPerMachine,
} from './utils'

export class BalanceStore {
  private machinesApiClient: MachinesApiClient

  private _latestEarningsPerMachineFetchMoment: Moment | null = null

  @observable
  private _earningsHistory: Map<number, number> = new Map()

  @observable
  private _earningsPerMachine: EarningPerMachine | null = {}

  @observable
  private daysShowingEarnings: ChartDaysShowing = 1

  @observable
  public currentBalance: number = 0

  @observable
  public currentHourlyEarningRatesPerMachine: CurrentHourlyEarningRatesPerMachine = {}

  @observable
  public lifetimeBalance: number = 0

  @observable
  public machines: Machine[] | null = []

  /** Total earnings for the last 24 hours */
  @observable
  public lastDayEarnings: number = 0

  /** Total earnings for the last 7 days */
  @observable
  public lastWeekEarnings: number = 0

  /** Total earnings for the last 30 days */
  @observable
  public lastMonthEarnings: number = 0

  @computed
  public get earningsHistory(): EarningWindow[] {
    return this.getEarningWindows(this.daysShowingEarnings, this._earningsHistory)
  }

  @computed
  public get earningsPerMachine(): EarningPerMachine {
    return this.getEarningWindowsPerMachine(this.daysShowingEarnings)
  }

  @computed
  public get getDaysShowingEarnings(): ChartDaysShowing {
    return this.daysShowingEarnings
  }

  @action
  viewLast24Hours = () => {
    this.daysShowingEarnings = 1
  }

  @action
  viewLast7Days = () => {
    this.daysShowingEarnings = 7
  }

  @action
  viewLast30Days = () => {
    this.daysShowingEarnings = 30
  }

  @action
  private getMachines = async () => {
    try {
      const machinesResponse = await this.machinesApiClient?.v2.machines.get()

      if (machinesResponse?.items) {
        const machines = machinesResponse?.items.filter((machine) => {
          if (machine.update_time) {
            return DateTime.fromJSDate(machine.update_time) > DateTime.now().minus({ days: 32 })
          }
          return false
        })

        if (machines.length > 0) {
          runInAction(() => {
            this.machines = machines
          })
          return this.machines
        }
      }
    } catch (error) {
      console.error('BalanceStore.getMultipleMachinesEarnings: ', error)
    }

    return null
  }

  private getEarningsPerMachine = async (machines: Machine[], chartsDaysShowing: ChartDaysShowing) => {
    try {
      return (
        await Promise.all(
          machines
            .filter((machine) => machine.machine_id)
            .map((machine) => {
              const timeframe: EarningHistoryTimeframeEnum =
                chartsDaysShowing === 1 ? '24h' : (`${chartsDaysShowing}d` as EarningHistoryTimeframeEnum)
              return this.machinesApiClient?.v2.machines
                .byMachine_id(getBaseKeyAsGuid(machine.machine_id as Guid))
                .earningHistory.get({ queryParameters: { timeframe } })
            }),
        )
      ).filter((value) => value) as MachineEarningHistory[]
    } catch (error) {
      console.error('BalanceStore.getEarningsPerMachine: ', error)
      return null
    }
  }

  public getCurrentEarningRatesPerMachine = async (machineIds: string[]) => {
    try {
      return (
        await Promise.all(
          machineIds.map((machineId) => {
            return this.axios.get(getMachineEarningRateEndpointPath(machineId))
          }),
        )
      ).reduce((currentHourlyEarningRatesPerMachine, response, index) => {
        const machineId = machineIds[index]
        if (machineId) {
          return { ...currentHourlyEarningRatesPerMachine, [machineId.toString()]: response.data * 12 }
        }
        return currentHourlyEarningRatesPerMachine
      }, {} as CurrentHourlyEarningRatesPerMachine)
    } catch (error) {
      console.error('BalanceStore.getCurrentEarningRatesPerMachine: ', error)
      return null
    }
  }

  @action
  fetchCurrentEarningRatesPerMachine = (machineIds?: string[]) => {
    this.getMachines()
      .then((machines) => {
        if (machines === null) {
          throw new Error('There is no machines')
        }

        return machineIds
          ? machineIds
          : (machines
              .filter((machine) => machine.machine_id)
              .map((machine) => machine.machine_id?.toString()) as string[])
      })
      .then((machinesIds) => {
        return this.getCurrentEarningRatesPerMachine(machinesIds)
      })
      .then((currentHourlyEarningRatesPerMachine) => {
        if (currentHourlyEarningRatesPerMachine) {
          return runInAction(() => {
            this.currentHourlyEarningRatesPerMachine = {
              ...this.currentHourlyEarningRatesPerMachine,
              ...currentHourlyEarningRatesPerMachine,
            }
          })
        }
        throw new Error('There are no current earning rates per machine id')
      })
      .catch((error) => {
        console.error('BalanceStore.fetchCurrentEarningRatesPerMachine: ', error)
      })
  }

  @action
  fetchEarningsPerMachine = () => {
    const shouldFetch =
      this._latestEarningsPerMachineFetchMoment === null ||
      moment().diff(this._latestEarningsPerMachineFetchMoment, 'hours') > 0

    if (shouldFetch) {
      this._latestEarningsPerMachineFetchMoment = moment()

      this.getMachines()
        .then((machines) => {
          if (machines === null) {
            throw new Error('There is no machines')
          }

          return this.getEarningsPerMachine(machines, 30)
        })
        .then((earningsPerMachine) => {
          if (earningsPerMachine) {
            return runInAction(() => {
              this._earningsPerMachine = normalizeEarningsPerMachine(earningsPerMachine)
            })
          }
          throw new Error('There are no earning per machines')
        })
        .catch((error) => {
          console.error('BalanceStore.getMultipleMachinesEarnings: ', error)
        })
    }
  }

  private getEarningWindowsPerMachine = (chartsDaysShowing: ChartDaysShowing): EarningPerMachine => {
    if (this._earningsPerMachine === null) {
      return {}
    }
    return Object.keys(this._earningsPerMachine).reduce((earningWindowsPerMachine, machineId) => {
      if (!this._earningsPerMachine) {
        return {}
      }

      const machineEarningsMap = this._earningsPerMachine[machineId]?.reduce((machineEarningsMap, item) => {
        machineEarningsMap.set(item.timestamp.unix(), item.earnings)
        return machineEarningsMap
      }, new Map())

      if (!machineEarningsMap) {
        return {}
      }

      return {
        ...earningWindowsPerMachine,
        [machineId]: this.getEarningWindows(chartsDaysShowing, machineEarningsMap, true),
      }
    }, {} as EarningPerMachine)
  }

  private getEarningWindows = (
    chartsDaysShowing: ChartDaysShowing,
    earningHistory: Map<number, number>,
    isPerMachineEarning?: boolean,
  ): EarningWindow[] => {
    const windows: EarningWindow[] = []

    const now = moment.utc()

    const threshold = moment(now).subtract(chartsDaysShowing, 'days')

    let batchedEarningWindows = new Map<number, number>()
    switch (chartsDaysShowing) {
      case 1:
        batchedEarningWindows = isPerMachineEarning ? batchEarningsWindow(earningHistory, 4) : earningHistory
        break
      case 7:
        batchedEarningWindows = batchEarningsWindow(earningHistory, 8)
        break
      case 30:
        batchedEarningWindows = batchEarningsWindow(earningHistory, 48)
        break
      default:
        batchedEarningWindows = earningHistory
    }

    for (let [unixTime, earning] of batchedEarningWindows) {
      const time = moment.unix(unixTime)

      if (time >= threshold) {
        windows.push({
          timestamp: time,
          earnings: earning,
        })
      }
    }

    const sortedEarningWindowsByTimestamp = windows.sort((a, b) => a.timestamp.diff(b.timestamp))

    if (chartsDaysShowing === 1) {
      return sortedEarningWindowsByTimestamp
    }

    const groupedByTheDayEarningWindows = getEarningWindowsGroupedByDay(
      sortedEarningWindowsByTimestamp,
      chartsDaysShowing,
    )

    return groupedByTheDayEarningWindows
  }

  constructor(private readonly axios: AxiosInstance) {
    this.machinesApiClient = getMachinesApiClient(axios)
  }

  @action.bound
  refreshBalanceAndHistory = flow(function* (this: BalanceStore) {
    yield Promise.allSettled([this.refreshBalance(), this.refreshBalanceHistory()])
  })

  @action.bound
  refreshBalance = flow(function* (this: BalanceStore) {
    try {
      const response = yield this.axios.get('/api/v1/profile/balance')
      this.currentBalance = response.data.currentBalance
      this.lifetimeBalance = response.data.lifetimeBalance
    } catch (error) {
      console.error('Balance error: ')
      console.error(error)
    }
  })

  @action.bound
  refreshBalanceHistory = flow(function* (this: BalanceStore) {
    try {
      const response = yield this.axios.get('/api/v2/reports/30-day-earning-history')

      const earningData = response.data

      const { lastMonthEarnings, lastWeekEarnings, lastDayEarnings, earningHistory } =
        normalizeEarningHistory(earningData)

      this.lastMonthEarnings = lastMonthEarnings
      this.lastWeekEarnings = lastWeekEarnings
      this.lastDayEarnings = lastDayEarnings
      this._earningsHistory = earningHistory
    } catch (error) {
      console.error('Balance history error: ')
      console.error(error)
    }
  })
}
