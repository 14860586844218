/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createMachineListFromDiscriminatorValue, createProblemDetailsFromDiscriminatorValue, type MachineList, type ProblemDetails } from '../../models/';
// @ts-ignore
import { type WithMachine_ItemRequestBuilder, WithMachine_ItemRequestBuilderNavigationMetadata, WithMachine_ItemRequestBuilderRequestsMetadata } from './item/';
// @ts-ignore
import { type BaseRequestBuilder, type KeysToExcludeForNavigationMetadata, type NavigationMetadata, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';
// @ts-ignore
import { type Guid } from 'guid-typescript';

/**
 * Operations for machines
 */
export interface MachinesRequestBuilder extends BaseRequestBuilder<MachinesRequestBuilder> {
    /**
     * Operations for a single machine
     * @param machine_id The unique machine identifier
     * @returns {WithMachine_ItemRequestBuilder}
     */
     byMachine_id(machine_id: Guid) : WithMachine_ItemRequestBuilder;
    /**
     * Gets the paginated list of machines
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<MachineList>}
     * @throws {ProblemDetails} error when the service returns a 401 status code
     * @throws {ProblemDetails} error when the service returns a 4XX or 5XX status code
     */
     get(requestConfiguration?: RequestConfiguration<MachinesRequestBuilderGetQueryParameters> | undefined) : Promise<MachineList | undefined>;
    /**
     * Gets the paginated list of machines
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toGetRequestInformation(requestConfiguration?: RequestConfiguration<MachinesRequestBuilderGetQueryParameters> | undefined) : RequestInformation;
}
/**
 * Gets the paginated list of machines
 */
export interface MachinesRequestBuilderGetQueryParameters {
    /**
     * The continuation token to fetch the next page of results
     */
    continuationToken?: string;
}
/**
 * Uri template for the request builder.
 */
export const MachinesRequestBuilderUriTemplate = "{+baseurl}/v2/machines{?continuation_token*}";
/**
 * Mapper for query parameters from symbol name to serialization name represented as a constant.
 */
const MachinesRequestBuilderGetQueryParametersMapper: Record<string, string> = {
    "continuationToken": "continuation_token",
};
/**
 * Metadata for all the navigation properties in the request builder.
 */
export const MachinesRequestBuilderNavigationMetadata: Record<Exclude<keyof MachinesRequestBuilder, KeysToExcludeForNavigationMetadata>, NavigationMetadata> = {
    byMachine_id: {
        requestsMetadata: WithMachine_ItemRequestBuilderRequestsMetadata,
        navigationMetadata: WithMachine_ItemRequestBuilderNavigationMetadata,
        pathParametersMappings: ["machine_id"],
    },
};
/**
 * Metadata for all the requests in the request builder.
 */
export const MachinesRequestBuilderRequestsMetadata: RequestsMetadata = {
    get: {
        uriTemplate: MachinesRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            401: createProblemDetailsFromDiscriminatorValue as ParsableFactory<Parsable>,
            XXX: createProblemDetailsFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createMachineListFromDiscriminatorValue,
        queryParametersMapper: MachinesRequestBuilderGetQueryParametersMapper,
    },
};
/* tslint:enable */
/* eslint-enable */
