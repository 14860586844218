/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { V2RequestBuilderNavigationMetadata, type V2RequestBuilder } from './v2'
// @ts-ignore
import {
  apiClientProxifier,
  registerDefaultDeserializer,
  registerDefaultSerializer,
  type BaseRequestBuilder,
  type KeysToExcludeForNavigationMetadata,
  type NavigationMetadata,
  type RequestAdapter,
} from '@microsoft/kiota-abstractions'
// @ts-ignore
import { JsonParseNodeFactory, JsonSerializationWriterFactory } from '@microsoft/kiota-serialization-json'

/**
 * Instantiates a new {@link MachinesApiClient} and sets the default values.
 * @param requestAdapter The request adapter to use to execute the requests.
 */
export function createMachinesApiClient(requestAdapter: RequestAdapter) {
  registerDefaultSerializer(JsonSerializationWriterFactory)
  registerDefaultDeserializer(JsonParseNodeFactory)
  if (requestAdapter.baseUrl === undefined || requestAdapter.baseUrl === '') {
    requestAdapter.baseUrl = 'https://app-api.salad.com/api'
  }
  const pathParameters: Record<string, unknown> = {
    baseurl: requestAdapter.baseUrl,
  }
  return apiClientProxifier<MachinesApiClient>(
    requestAdapter,
    pathParameters,
    MachinesApiClientNavigationMetadata,
    undefined,
  )
}
/**
 * The main entry point of the SDK, exposes the configuration and the fluent API.
 */
export interface MachinesApiClient extends BaseRequestBuilder<MachinesApiClient> {
  /**
   * The v2 property
   */
  get v2(): V2RequestBuilder
}
/**
 * Uri template for the request builder.
 */
export const MachinesApiClientUriTemplate = '{+baseurl}'
/**
 * Metadata for all the navigation properties in the request builder.
 */
export const MachinesApiClientNavigationMetadata: Record<
  Exclude<keyof MachinesApiClient, KeysToExcludeForNavigationMetadata>,
  NavigationMetadata
> = {
  v2: {
    navigationMetadata: V2RequestBuilderNavigationMetadata,
  },
}
/* tslint:enable */
/* eslint-enable */
