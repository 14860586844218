/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createMachineEarningHistoryFromDiscriminatorValue, createProblemDetailsFromDiscriminatorValue, EarningHistoryTimeframeEnum, type MachineEarningHistory, type ProblemDetails } from '../../../../models/';
// @ts-ignore
import { type BaseRequestBuilder, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Operations for a single machine
 */
export interface EarningHistoryRequestBuilder extends BaseRequestBuilder<EarningHistoryRequestBuilder> {
    /**
     * Gets a single machine earning history within a given timeframe by its unique identifier
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<MachineEarningHistory>}
     * @throws {ProblemDetails} error when the service returns a 400 status code
     * @throws {ProblemDetails} error when the service returns a 401 status code
     * @throws {ProblemDetails} error when the service returns a 404 status code
     * @throws {ProblemDetails} error when the service returns a 4XX or 5XX status code
     */
     get(requestConfiguration?: RequestConfiguration<EarningHistoryRequestBuilderGetQueryParameters> | undefined) : Promise<MachineEarningHistory | undefined>;
    /**
     * Gets a single machine earning history within a given timeframe by its unique identifier
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toGetRequestInformation(requestConfiguration?: RequestConfiguration<EarningHistoryRequestBuilderGetQueryParameters> | undefined) : RequestInformation;
}
/**
 * Gets a single machine earning history within a given timeframe by its unique identifier
 */
export interface EarningHistoryRequestBuilderGetQueryParameters {
    /**
     * The earning history time frame
     */
    timeframe?: EarningHistoryTimeframeEnum;
}
/**
 * Uri template for the request builder.
 */
export const EarningHistoryRequestBuilderUriTemplate = "{+baseurl}/v2/machines/{machine_id}/earning-history{?timeframe*}";
/**
 * Metadata for all the requests in the request builder.
 */
export const EarningHistoryRequestBuilderRequestsMetadata: RequestsMetadata = {
    get: {
        uriTemplate: EarningHistoryRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createProblemDetailsFromDiscriminatorValue as ParsableFactory<Parsable>,
            401: createProblemDetailsFromDiscriminatorValue as ParsableFactory<Parsable>,
            404: createProblemDetailsFromDiscriminatorValue as ParsableFactory<Parsable>,
            XXX: createProblemDetailsFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createMachineEarningHistoryFromDiscriminatorValue,
    },
};
/* tslint:enable */
/* eslint-enable */
