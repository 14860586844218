/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { MachinesRequestBuilderNavigationMetadata, MachinesRequestBuilderRequestsMetadata, type MachinesRequestBuilder } from './machines/';
// @ts-ignore
import { type BaseRequestBuilder, type KeysToExcludeForNavigationMetadata, type NavigationMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /v2
 */
export interface V2RequestBuilder extends BaseRequestBuilder<V2RequestBuilder> {
    /**
     * Operations for machines
     */
    get machines(): MachinesRequestBuilder;
}
/**
 * Uri template for the request builder.
 */
export const V2RequestBuilderUriTemplate = "{+baseurl}/v2";
/**
 * Metadata for all the navigation properties in the request builder.
 */
export const V2RequestBuilderNavigationMetadata: Record<Exclude<keyof V2RequestBuilder, KeysToExcludeForNavigationMetadata>, NavigationMetadata> = {
    machines: {
        requestsMetadata: MachinesRequestBuilderRequestsMetadata,
        navigationMetadata: MachinesRequestBuilderNavigationMetadata,
    },
};
/* tslint:enable */
/* eslint-enable */
